<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LAS INCIDENCIAS:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col md="12" class="my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-thumbs-down fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaNoConformidades.length}}</span>
                            <br>
                            <span class="text-muted">No conformidades registradas</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <!-- <i class="fas fa-filter fa-md"></i> <span class="h5"> Filtro </span> -->
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión del informe global </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block size="md" variant="custom" :to="{name: 'No Conformidades'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br>Volver
                            </b-button>
                        </b-col>

                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Norma </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="12" class="text-center my-2 ">
                            <b-table bordered hover show-empty small responsive outlined :items="listaNormas" thead-class="hidden_header" empty-text="Aún no hay datos en esta sección.">
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-pie fa-md"></i> <span class="h5"> Distribución por normas </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center">
                        <b-col md="11">
                            <CChartPie v-if="disabledChart" :options="optionsNormas" class="my-2" :datasets="dataSetsNormas" :labels="labelNormas" />
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Categorías </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="12" class="text-center my-2 ">
                            <b-table bordered hover show-empty small responsive outlined :items="listaCategorias" thead-class="hidden_header" empty-text="Aún no hay datos en esta sección.">
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-pie fa-md"></i> <span class="h5"> Distribución por categorías </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center">
                        <b-col md="11">
                            <CChartPie v-if="disabledChart" :options="optionsCategorias" class="my-2" :datasets="dataSetsCategorias" :labels="labelCategorias" />
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Procesos </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="12" class="text-center my-2 ">
                            <b-table bordered hover show-empty small responsive outlined :items="listaProcesos" thead-class="hidden_header" empty-text="Aún no hay datos en esta sección.">
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-pie fa-md"></i> <span class="h5"> Distribución por procesos </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center">
                        <b-col md="11">
                            <CChartPie v-if="disabledChart" :options="optionsProcesos" class="my-2" :datasets="dataSetsProcesos" :labels="labelProcesos" />
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-flag fa-md"></i> <span class="h5"> Cierres </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="12" class="text-center my-2 ">
                            <b-table bordered hover show-empty small responsive outlined :items="listaCierres" thead-class="hidden_header" empty-text="Aún no hay datos en esta sección.">
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-circle-notch fa-md"></i> <span class="h5"> Cierres</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center">
                        <b-col md="10">
                            <CChartDoughnut ref="chartpl" :options="optionsCierres" class="my-2" :datasets="dataSetsCierres" :labels="labelCierres" />
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-clock fa-md"></i> <span class="h5"> Tiempo de resolución </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="12" class="text-center mt-2 ">
                            <b-table bordered hover show-empty class=" pb-0" small responsive outlined :items="listaResolucion" thead-class="hidden_header" empty-text="Aún no hay datos en esta sección.">
                            </b-table>

                            <b-table bordered hover show-empty class="mt-0" small responsive outlined :items="listaTiempo" thead-class="hidden_header" empty-text="Aún no hay datos en esta sección.">
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-pie fa-md"></i> <span class="h5"> Distribución por tiempos</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center">
                        <b-col md="11">
                            <CChartPie :options="optionsResolucion" class="my-2" :datasets="dataSetsResolucion" :labels="labelResolucion" />
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import {
    CChartPie,
    CChartDoughnut
} from '@coreui/vue-chartjs'

import axios from "axios";
import CONSTANTES from "@/Constantes.js";
export default {
    components: {
        CChartPie,
        CChartDoughnut
    },
    data() {
        return {
            disabledChart: false,
            datosSession: {
                idCliente: ''
            },
            listaNoConformidades: [],

            //Normas 
            listaNormas: [],
            labelNormas: [],
            colorsNormas: [],
            dataNormas: [],
            optionsNormas: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },
            templateNormas: [{
                idNorma: 1,
                color: '#fdcd60d4',
                descripcion: 'Calidad (9001)'
            }, {
                idNorma: 2,
                color: '#7fc384d4',
                descripcion: 'MedioAmbiente (14001)'
            }, {
                idNorma: 3,
                color: '#f16553d4',
                descripcion: 'Seguridad Laboral (45001)'
            }, {
                idNorma: 4,
                color: '#91bbe0d4',
                descripcion: 'AntiSoborno (37001)'
            }],

            //Categorias
            listaCategorias: [],
            labelCategorias: [],
            colorsCategorias: [],
            dataCategorias: [],
            optionsCategorias: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },

            templateCategorias: [],

            //Procesos
            listaProcesos: [],
            labelProcesos: [],
            colorsProcesos: [],
            dataProcesos: [],
            optionsProcesos: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },
            templateProcesos: [],

            //Cierres

            listaCierres: [],
            labelCierres: [],
            colorsCierres: [],
            dataCierres: [],
            optionsCierres: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'bottom',
                    align: 'center'
                },
                cutoutPercentage: 65,
            },
            templateCierres: [{
                isResuelta: 1,
                descripcion: 'RESUELTA',
                color: '#7fc384d4'
            }, {
                isResuelta: 2,
                descripcion: 'PENDIENTE DE RESOLUCIÓN',
                color: '#f16553d4'
            }],

            //Resolucion
            listaResolucion: [],
            labelResolucion: ['RESUELTO <1 SEMANA', 'RESUELTO ENTRE 1 Y 4 SEMANAS', 'RESUELTO ENTRE 4 Y 8 SEMANAS', 'RESUELTO > 8 SEMANAS'],
            colorsResolucion: ['#7881ef', '#9d90bd', '#d49bc0', '#f7a28d'],
            dataResolucion: [],
            optionsResolucion: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },

            listaTiempo: [],
        }
    },
    methods: {

        listarNoConformidades() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-no-conformidades", {
                    params: {
                        idCliente: me.datosSession.idCliente
                    }
                })
                .then(function (response) {
                    me.listaNoConformidades = response.data
                    me.listarProcesos();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarProcesos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-procesos", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            all: 1
                        },
                    }
                )
                .then(function (response) {
                    me.templateProcesos = response.data;
                    me.listarCategorias();

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarCategorias() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-categorias-incidentes", {
                        params: {
                            idCliente: me.datosSession.idCliente,
                            all: 1
                        },
                    }
                )
                .then(function (response) {
                    me.templateCategorias = response.data;
                    me.generarReporte();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        generarReporte() {
            //Quitar duplicadas
            function removerDataDuplicada(arr, key) {
                return [...new Map(arr.map(item => [item[key], item])).values()]
            }

            let genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

            function diferenciaDias(f1, f2) {
                let aFecha1 = f1.split('-');
                let aFecha2 = f2.split('-');
                let fFecha1 = Date.UTC(aFecha1[0], aFecha1[1] - 1, aFecha1[2]);
                let fFecha2 = Date.UTC(aFecha2[0], aFecha2[1] - 1, aFecha2[2]);
                let dif = fFecha2 - fFecha1;
                let dias = Math.floor(dif / (1000 * 60 * 60 * 24));
                return dias;
            }

            //Normas
            this.labelNormas = [];
            this.colorsNormas = [];
            this.dataNormas = [];
            this.listaNormas = [];
            for (let n of removerDataDuplicada(this.listaNoConformidades, 'norma')) {
                let dataTemplate = this.templateNormas.filter(x => x.idNorma == n.norma);
                let dataOriginal = this.listaNoConformidades.filter(x => x.norma == n.norma);

                this.labelNormas.push(dataTemplate[0].descripcion);
                this.colorsNormas.push(dataTemplate[0].color);
                this.dataNormas.push(dataOriginal.length);
                this.listaNormas.push({
                    nombre: dataTemplate[0].descripcion,
                    cantidad: dataOriginal.length,
                    porcentaje: `${(dataOriginal.length * 100 / this.listaNoConformidades.length).toFixed(2)}%`
                })
            }

            //Categorias
            this.labelCategorias = [];
            this.colorsCategorias = [];
            this.dataCategorias = [];
            this.listaCategorias = [];

            for (let c of removerDataDuplicada(this.listaNoConformidades, 'idCategoriaIncidente')) {
                let dataTemplate = this.templateCategorias.filter(x => x.idCategoriaIncidente == c.idCategoriaIncidente);
                let dataOriginal = this.listaNoConformidades.filter(x => x.idCategoriaIncidente == c.idCategoriaIncidente);
                this.labelCategorias.push(dataTemplate[0].nombre);
                this.colorsCategorias.push(`#${genRanHex(6)}`);
                this.dataCategorias.push(dataOriginal.length)
                this.listaCategorias.push({
                    nombre: dataTemplate[0].nombre,
                    cantidad: dataOriginal.length,
                    porcentaje: `${(dataOriginal.length * 100 / this.listaNoConformidades.length).toFixed(2)}%`
                })
            }

            // Procesos
            this.labelProcesos = [];
            this.colorsProcesos = [];
            this.dataProcesos = [];
            this.listaProcesos = [];
            for (let p of removerDataDuplicada(this.listaNoConformidades, 'idProceso')) {
                let dataTemplate = this.templateProcesos.filter(x => x.idProceso == p.idProceso);
                let dataOriginal = this.listaNoConformidades.filter(x => x.idProceso == p.idProceso);
                this.labelProcesos.push(dataTemplate[0].nombre);
                this.colorsProcesos.push(`#${genRanHex(6)}`);
                this.dataProcesos.push(dataOriginal.length)
                this.listaProcesos.push({
                    nombre: dataTemplate[0].nombre,
                    cantidad: dataOriginal.length,
                    porcentaje: `${(dataOriginal.length * 100 / this.listaNoConformidades.length).toFixed(2)}%`
                })
            }

            //Cierres
            this.labelCierres = [];
            this.colorsCierres = [];
            this.dataCierres = [];
            this.listaCierres = [];
            for (let r of removerDataDuplicada(this.listaNoConformidades, 'isResuelta')) {
                let dataTemplate = this.templateCierres.filter(x => x.isResuelta == r.isResuelta);
                let dataOriginal = this.listaNoConformidades.filter(x => x.isResuelta == r.isResuelta);
                this.labelCierres.push(dataTemplate[0].descripcion);
                this.colorsCierres.push(dataTemplate[0].color);
                this.dataCierres.push(dataOriginal.length)
                this.listaCierres.push({
                    nombre: dataTemplate[0].descripcion,
                    cantidad: dataOriginal.length,
                    porcentaje: `${(dataOriginal.length * 100 / this.listaNoConformidades.length).toFixed(2)}%`
                })
            }
            //Resolucion
            // for (let y of removerDataDuplicada(this.listaNoConformidades, 'tipoSemana').sort(function (a, b) {
            //         return parseInt(a.tipoSemana) - parseInt(b.tipoSemana);
            //     })) {

            //     let dataTemplate = this.templateResolucion.filter(x => x.tiempo == y.tipoSemana);
            //     let dataOriginal = this.listaNoConformidades.filter(x => x.tipoSemana == y.tipoSemana);
            //     this.labelResolucion.push(dataTemplate[0].descripcion);
            //     this.colorsResolucion.push(dataTemplate[0].color);
            //     this.dataResolucion.push(dataOriginal.length)
            //     this.listaResolucion.push({
            //         nombre: dataTemplate[0].descripcion,
            //         cantidad: dataOriginal.length,
            //         porcentaje: `${(dataOriginal.length * 100 / this.listaNoConformidades.length).toFixed(2)}%`
            //     })
            // }

            // this.dataResolucion = [];
            this.listaResolucion = [];
            let templateResolucion = [];
            this.dataResolucion = [];

            templateResolucion = [{
                cantidad: 0,
                descripcion: 'RESUELTO <1 SEMANA'
            }, {
                cantidad: 0,
                descripcion: 'RESUELTO ENTRE 1 Y 4 SEMANAS',
            }, {
                cantidad: 0,
                descripcion: 'RESUELTO ENTRE 4 Y 8 SEMANAS',
            }, {
                cantidad: 0,
                descripcion: 'RESUELTO > 8 SEMANAS',
            }];

            for (let y of this.listaNoConformidades) {
                if (y.cantidadSemanas < 1) {
                    templateResolucion[0].cantidad++;
                } else if (y.cantidadSemanas >= 1 && y.cantidadSemanas < 4) {
                    templateResolucion[1].cantidad++;
                } else if (y.cantidadSemanas >= 4 && y.cantidadSemanas < 8) {
                    templateResolucion[2].cantidad++;
                } else if (y.cantidadSemanas >= 8) {
                    templateResolucion[3].cantidad++;
                }
            }

            this.dataResolucion.push(templateResolucion[0].cantidad, templateResolucion[1].cantidad, templateResolucion[2].cantidad, templateResolucion[3].cantidad)

            for (let yx of templateResolucion) {
                this.listaResolucion.push({
                    nombre: yx.descripcion,
                    cantidad: yx.cantidad,
                    porcentaje: this.listaNoConformidades.length > 0 ? `${(yx.cantidad * 100 / this.listaNoConformidades.length).toFixed(2)}%` : '00.00%'
                })
            }

            //Total dias

            let dias = [];
            this.listaTiempo = [];
            for (let t of this.listaNoConformidades) {
                dias.push(diferenciaDias(t.fecha, t.fechaResolucion));
            }
            if (this.listaNoConformidades.length > 0) {
                let suma = dias.reduce((datoPrevio, datoActual) => datoActual += datoPrevio);
                let promedio = suma / dias.length;

                this.listaTiempo.push({
                    nombre: "TIEMPO MEDIO",
                    cantidad: `${promedio.toFixed(2)} dias`,
                })
            } else {
                this.listaTiempo.push({
                    nombre: "TIEMPO MEDIO",
                    cantidad: '0 dias',
                })
            }

            this.disabledChart = true
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },
    computed: {
        dataSetsNormas() {
            return [{
                borderWidth: 3,
                backgroundColor: this.colorsNormas,
                data: this.dataNormas,
            }]
        },
        dataSetsCategorias() {
            return [{
                borderWidth: 3,
                backgroundColor: this.colorsCategorias,
                data: this.dataCategorias,
            }]
        },
        dataSetsProcesos() {
            return [{
                borderWidth: 3,
                backgroundColor: this.colorsProcesos,
                data: this.dataProcesos
            }]
        },
        dataSetsCierres() {
            return [{
                backgroundColor: this.colorsCierres,
                data: this.dataCierres
            }]
        },
        dataSetsResolucion() {
            return [{
                borderWidth: 3,
                backgroundColor: this.colorsResolucion,
                data: this.dataResolucion
            }]
        }
    },
    watch: {

    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarNoConformidades();
        }
    }

}
</script>
